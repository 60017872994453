import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import HamburgerMenu from "../../components/hamburger-menu";
import HeaderSearch from "../../components/header-search";
import Logo from "../../components/logo/index";
import PopupSearch from "../../components/popup-search";
import ProgressBar from "../../components/fixed-top-scrollbar";
import { Link } from "react-router-dom";

const Header = ({ classOption }) => {
    // Define state variables using the useState hook
    const [ofcanvasShow, setOffcanvasShow] = useState(false); // State for controlling the hamburger menu
    const [searchbarShow, setSearchbarShow] = useState(false); // State for controlling the header search bar
    const [scroll, setScroll] = useState(0); // State to track the scroll position
    const [headerTop, setHeaderTop] = useState(0); // State to store the header's top position

    // Handler to toggle the hamburger menu
    const onCanvasHandler = () => {
        setOffcanvasShow((prev) => !prev);
    };

    // Handler to toggle the header search bar
    const onSearchHandler = () => {
        setSearchbarShow((prev) => !prev);
    };

    // Effect hook to set up scroll event listener and update header position
    useEffect(() => {
        const header = document.querySelector(".header-area");
        setHeaderTop(header.offsetTop);

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Cleanup: Remove scroll event listener when component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Handler to update scroll position
    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <Fragment>
            {/* Header component */}
            <header
                className={`header-area header-default sticky-header ${classOption} ${
                    scroll > headerTop ? "sticky" : ""
                }`}
            >
                <ProgressBar />
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-auto">
                            {/* Hamburger menu button */}
                            <div className="header-action-area">
                                <button
                                    className="btn-menu"
                                    onClick={onCanvasHandler}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <span className="menu-text">Menu</span>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 col-sm-6 col-auto">
                            {/* Logo */}
                            <div className="header-logo-area">
                                {/* <Logo
                                    image={`${process.env.PUBLIC_URL}/img/logo.png`}
                                /> */}
                                <span className="logo-text">
                                    <Link to={process.env.PUBLIC_URL + "/"}>
                                        LNACARLA
                                    </Link>
                                </span>
                            </div>
                        </div>

                        <div className="col-auto">
                            {/* Header search component */}
                            {/* <HeaderSearch onClick={onSearchHandler} /> */}
                        </div>
                    </div>
                </div>
            </header>

            {/* Popup search component */}
            <PopupSearch show={searchbarShow} onClose={onSearchHandler} />

            {/* Hamburger menu component */}
            <HamburgerMenu show={ofcanvasShow} onClose={onCanvasHandler} />
        </Fragment>
    );
};

// PropTypes for type checking and validation
Header.propTypes = {
    classOption: PropTypes.string,
};

// Default props for the classOption
Header.defaultProps = {
    classOption: "header-area header-default sticky-header",
};

export default Header;
