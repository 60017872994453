import imagesloaded from "imagesloaded";
import Isotope from "isotope-layout";
import { useEffect, useState } from "react";
import { flatDeep } from "../utils";

const useMasonry = (
    PortfolioData,
    masonryListWrap,
    masonryGrid,
    btnWrap,
    btn
) => {
    // Define a state variable to store categories
    const [categories, setCategories] = useState([]);

    /*
       The useEffect hook is a fundamental part of React that allows you to perform side effects
       in your functional components. Side effects typically involve operations
       that don't directly relate to rendering components, such as data fetching,
       DOM manipulation, or setting up subscriptions.
    */
    /*
        Explanations:

        The useEffect function takes two arguments:

                1)  The first argument is a function that contains the code you want to run as a side effect.

                    This function will be executed after the component has rendered.

                2) The second argument is an array of dependencies.

                These dependencies are variables or values from the component's scope

                that the useEffect function relies on.

                When any of the dependencies change, the useEffect code will be re-executed.

                If the dependencies array is empty ([]), the useEffect code runs only once after the initial render.

                Here's a breakdown of how it works:

                When the component is initially rendered, React will execute the code inside the useEffect function 
                
                because there are no dependencies specified, so it acts like componentDidMount in class components.

                If you specify one or more dependencies in the array, such as [dependency1, dependency2],

                React will re-run the useEffect code whenever any of those dependencies change.

                This is similar to the behavior of componentDidUpdate in class components.

                When React unmounts the component or when the dependencies change and trigger a re-render, React will also clean up any effects from the previous render.

                In your specific code example, the useEffect hook is used to set up various functionality related to handling portfolio item filtering with the Isotope library. 
                
                The code inside this useEffect will run after the component is initially rendered
                
                and whenever any of the specified dependencies (e.g., btn, btnWrap, masonryGrid, masonryListWrap, PortfolioData) change.

    */
    useEffect(() => {
        // Extract all categories from PortfolioData
        const mixCategories = PortfolioData.map((item) => {
            return item.categories.map((cat) => cat);
        });

        // Flatten the nested arrays into a single array
        const allCat = flatDeep(mixCategories, Infinity);

        // Deduplicate the categories and store them in state
        const commonCategories = [...new Set(allCat)];
        setCategories(commonCategories);

        // This is for handling images loading
        const masonryList = document.querySelector(masonryListWrap);
        imagesloaded(masonryList, () => {
            const projectItems = masonryList.querySelectorAll(masonryGrid);

            // Add a class to every 2nd project item for grid styling
            let start = 1;
            while (start < projectItems.length) {
                projectItems[start].classList.add("grid-width-2");
                start += 4;
            }

            // Initialize Isotope for filtering
            let Iso = new Isotope(masonryList, {
                itemSelector: masonryGrid,
            });

            // Handle filter button clicks
            const filterWrap = document.querySelector(btnWrap);
            const filterItems = document.querySelectorAll(btn);
            filterItems.forEach((filterItem) => {
                filterItem.addEventListener("click", (e) => {
                    const filterCate = filterItem.dataset.filter;

                    // Remove the "is-checked" class from all filter items
                    filterWrap
                        .querySelector(".is-checked")
                        .classList.remove("is-checked");

                    // Add the "is-checked" class to the clicked filter item
                    e.target.classList.add("is-checked");

                    // Use Isotope to arrange the items based on the selected filter
                    Iso.arrange({
                        filter: filterCate,
                    });
                });
            });
        });
    }, [btn, btnWrap, masonryGrid, masonryListWrap, PortfolioData]);

    // Return the categories to be used in the component
    return { categories };
};

export default useMasonry;
