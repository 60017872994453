// Importing the PropTypes library to define the expected types of the component's props
import PropTypes from "prop-types";

// Importing the Link component from "react-router-dom" for creating links in the application
import { Link } from "react-router-dom";

// Defining a functional component named Brand, which takes a single prop 'data'
const Brand = ({ data }) => {
    // Returning JSX that represents the structure of the component
    return (
        // Wrapping the JSX in a <div> element with a class name of "brand-logo-item"
        <div className="brand-logo-item">
            {/* Creating a link with the brand's website link */}
            <a href={data.website_link}>
                {/* Displaying the brand's logo image */}
                <img
                    // Generating the image source using the PUBLIC_URL and the image path from the 'data' prop
                    src={process.env.PUBLIC_URL + data.image}
                    alt="Brand-Logo"
                />
            </a>
        </div>
    );
};

// Specifying the prop types for the 'data' prop to ensure it is an object
Brand.propTypes = {
    data: PropTypes.object,
};

// Exporting the Brand component as the default export of this module
export default Brand;
