// Import PropTypes library for defining the prop types of the component
import PropTypes from "prop-types";

// Import Link component from react-router-dom
import { Link } from "react-router-dom";

/*
    Intro component in React, which appears to represent an introductory section
    with background image, title, subtitle, description, and a link/button. 

    This component takes a prop named data, which is expected to be an object containing information 
    such as
    
    subTitle,
    title,
    desc (description),
    backgroundImage,
    slug (used to create the link),
    and buttonText (text displayed on the button).

    The component structure includes a background image, a title, a subtitle, a description, and a link/button that navigates to a portfolio item.
*/
// Define the Intro functional component with a 'data' prop
const Intro = ({ data }) => {
    return (
        // Container div for the introductory section with background image
        <div
            className="intro-section section overlay"
            style={{
                backgroundImage: `url(${
                    process.env.PUBLIC_URL + data.backgroundImage
                })`,
            }}
        >
            <div className="container">
                <div className="row row-cols-lg-1 row-cols-1">
                    <div className="col align-self-center">
                        {/* Content of the introductory section */}
                        <div className="intro-content">
                            {/* Subtitle element */}
                            <span className="sub-title">{data.subTitle}</span>

                            {/* Title element */}
                            <h2 className="title">{data.title}</h2>

                            {/* Description element */}
                            <div className="desc">
                                <p>{data.desc}</p>
                            </div>

                            {/* Link or button to navigate to a portfolio item */}
                            <Link
                                to={
                                    process.env.PUBLIC_URL +
                                    "/portfolio/" +
                                    data.slug
                                }
                                className="intro-btn"
                            >
                                {data.buttonText}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Define prop types for the Intro component
Intro.propTypes = {
    data: PropTypes.object, // Expects a prop 'data' of type object
};

// Export the Intro component as the default export
export default Intro;
