// Import PropTypes library for defining the prop types of the component
import PropTypes from "prop-types";

/*
    This code defines a PopupSearch component in React, which appears to represent a search popup with an input field.
    It can be toggled to show or hide based on the show prop, and it includes a close button (onClose function).
    This component is designed to be a search popup that can be shown or hidden based on the value of the show prop.
    It includes an input field for search, a search button, and a close button.
    The onClose function is called when the close button is clicked.
*/

// Define the PopupSearch functional component with 'show' and 'onClose' props
const PopupSearch = ({ show, onClose }) => {
    return (
        // Container div with dynamic classes based on the 'show' prop
        <div className={`main-search-active ${show ? "inside" : ""}`}>
            {/* Search close button */}
            <div className="sidebar-search-icon">
                <button className="search-close" onClick={onClose}>
                    {/* Close icon */}
                    <i className="icofont-close-line"></i>
                </button>
            </div>

            {/* Search input form */}
            <div className="sidebar-search-input">
                <form action="#">
                    <div className="form-search">
                        {/* Input field for search */}
                        <input
                            id="search"
                            className="input-text"
                            placeholder=""
                            type="search"
                        />

                        {/* Search button */}
                        <button>
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </form>

                {/* Description below the search input */}
                <p className="form-description">Hit enter to search</p>
            </div>
        </div>
    );
};

// Define prop types for the PopupSearch component
PopupSearch.propTypes = {
    show: PropTypes.bool, // Expects a prop 'show' of type boolean
    onClose: PropTypes.func, // Expects a prop 'onClose' of type function
};

// Export the PopupSearch component as the default export
export default PopupSearch;
