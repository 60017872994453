// Import necessary dependencies and components
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import PortfolioDetailsContainer from "../containers/portfolio-details";
import PortfolioData from "../data/portfolio.json";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";
import { useLocation } from "react-router-dom";
import LoaderComponent from "../components/loader";

/*
    Here's a summary of what this code does:

    It imports various dependencies and custom components required for rendering a portfolio details page.

    The PortfolioDetails component is defined as a functional component.

    It receives the match prop from React Router, which typically contains information about the current route, including URL parameters.


    Inside the component:

    It extracts the id parameter from the match prop and converts it to an integer using parseInt. This is likely used to identify the specific portfolio project to display.

    It filters the PortfolioData array to find the project with the matching id. The filtered data is stored in the data variable.

    It renders a JSX structure for the component, including setting SEO metadata, rendering header, main content, footer, and a "scroll to top" button.

    PropTypes are defined to specify the expected shape of the match prop, ensuring that the id parameter is either a string or a number.

    The PortfolioDetails component is exported as the default export of this module, making it available for use in other parts of the application.
*/

// Define a functional React component called PortfolioDetails
const PortfolioDetails = ({
    match: {
        params: { id, slug }, // Destructure 'id' from the 'params' object within the 'match' prop
    },
}) => {
    // Parse 'id' as an integer (base 10)
    //const projectId = parseInt(id, 10);

    // Filter the portfolio data to find the project with a matching 'id'
    // const data = PortfolioData.filter((project) => project.id === projectId);

    const data = PortfolioData.filter((project) => project.slug === slug);

    const location = useLocation();
    // const [animationClass, setAnimationClass] = useState("");

    /*
    
    useEffect(() => {
        // The code inside this useEffect will run whenever the route path changes.
        // You can access the current route path from the `location` object.
        console.log("Route path has changed to:'", location.pathname);

        // Apply an animation class based on the route change.
        // For example, you can apply a fade-in animation class.
        // setAnimationClass("fadeOut");

        // You can perform any actions or logic here based on the route change.
        // For example, you might want to fetch data or update component state.

        // Make sure to include `location` in the dependency array if you want
        // this effect to run whenever `location` changes.
    }, [location]);

    */

    // Render the JSX structure for this component
    return (
        <React.Fragment>
            <Layout>
                {/* Set SEO metadata for the page */}
                <SEO title={`lnacarla – ${data[0].title}`} />
                <div className={"wrapper home-default-wrapper"}>
                    {/* Render the Header component with a 'classOption' prop */}
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        {/* Render the PortfolioDetailsContainer with filtered project data */}
                        <PortfolioDetailsContainer data={data[0]} />
                        {/* Loader animation project page transition */}
                        <LoaderComponent />
                    </div>
                    {/* Render the Footer component */}
                    <Footer />
                    {/* Render the ScrollToTop component */}
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

// Define PropTypes to specify the expected shape of the 'match' prop
PortfolioDetails.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            slug: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
    }),
};

// Export the PortfolioDetails component as the default export
export default PortfolioDetails;
