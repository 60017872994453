import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import ContactContainer from "../containers/contact";
// import MapContainer from "../containers/global/map";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";

const Contact = () => {
    return (
        <React.Fragment>
            {/* Wrapping the content in a Layout component */}
            <Layout>
                {/* Setting SEO metadata */}
                <SEO title="lnacarla || Contact" />
                <div className="wrapper home-default-wrapper">
                    {/* Including a Header component with a specific class option */}
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        {/* Including a ContactContainer component */}
                        <ContactContainer />
                        {/* Including a MapContainer component */}
                        {/* <MapContainer /> */}
                    </div>
                    {/* Including a Footer component */}
                    <Footer />
                    {/* Including a ScrollToTop component */}
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default Contact;
