// Importing PropTypes for type checking
import PropTypes from "prop-types";

// Importing the PageTitle component
import PageTitle from "../../../components/page-title";

/*
    This component is a functional component that displays a page title area with a container, row, and column structure.
    It uses the PageTitle component to render the main title and subtitle.
    The title and subTitle are expected to be strings and are type-checked using PropTypes.
*/

// Functional component for PageTitleContainerTwo with props (title, subTitle)
const PageTitleContainerTwo = ({ title, subTitle }) => {
    return (
        <div className="page-title-area">
            {" "}
            {/* Container for the page title area */}
            <div className="container">
                {" "}
                {/* Bootstrap container */}
                <div className="row">
                    {" "}
                    {/* Bootstrap row */}
                    <div className="col-lg-12 text-center" data-aos="fade-up">
                        {" "}
                        {/* Bootstrap column for the page title */}
                        <PageTitle
                            classOption="page-title-content" // Additional class options for styling
                            subTitle={subTitle} // Subtitle passed as a prop
                            title={title} // Main title passed as a prop
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

// PropTypes for type-checking the props
PageTitleContainerTwo.propTypes = {
    subTitle: PropTypes.string, // Subtitle prop is expected to be a string
    title: PropTypes.string, // Title prop is expected to be a string
};

export default PageTitleContainerTwo; // Export the PageTitleContainerTwo component
