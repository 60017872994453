// Importing necessary components and libraries
import { LightgalleryProvider } from "react-lightgallery";
import PortfolioFilter from "../../../components/portfolio/portfolio-filter";
import PortfolioItem from "../../../components/portfolio/portfolio-item";
import PortfolioData from "../../../data/portfolio.json";
import useMasonry from "../../../hooks/use-masonry";
import { slugify } from "../../../utils";

/*
    This component represents a portfolio section that displays items in a masonry grid with the ability
    to filter items by category. 
    It uses the LightgalleryProvider for a lightbox effect and a button for loading more portfolio items.
*/
// Functional component for the PortfolioContainer
const PortfolioContainer = () => {
    // Using the useMasonry hook to get categories for filtering
    const { categories } = useMasonry(
        PortfolioData,
        ".portfolio-list",
        ".masonry-grid",
        ".messonry-button",
        ".messonry-button button"
    );

    return (
        <div className="portfolio-area portfolio-default-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {/* PortfolioFilter component for category filtering */}
                        <div className="messonry-button text-center mb-50">
                            <PortfolioFilter categories={categories} />
                        </div>
                    </div>
                </div>

                {/* Portfolio items displayed in a masonry grid */}
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 portfolio-list mb-n30">
                    {/* Placeholder div for Isotope resizing */}
                    <div className="col resizer"></div>

                    {/* LightgalleryProvider to enable lightbox functionality */}
                    <LightgalleryProvider>
                        {PortfolioData &&
                            // Mapping through PortfolioData to render PortfolioItem components
                            PortfolioData.map((portfolio) => (
                                <div
                                    key={portfolio.id}
                                    // eslint-disable-next-line react/no-unknown-property
                                    group={`any`}
                                    className={`col masonry-grid mb-30 ${portfolio.categories
                                        .map((cat) => slugify(cat))
                                        .join(" ")}`}
                                >
                                    {/* PortfolioItem component to display individual portfolio items */}
                                    <PortfolioItem portfolio={portfolio} />
                                </div>
                            ))}
                    </LightgalleryProvider>
                </div>
            </div>
        </div>
    );
};

export default PortfolioContainer; // Exporting the PortfolioContainer component
