// Import PropTypes for defining prop types and the 'slugify' function from a utility module
import PropTypes from "prop-types";
import { slugify } from "../../../utils";

/*

    Here's what each part of the code does:

    We import the necessary dependencies, PropTypes for defining prop types and the slugify function from a utility module.

    The PortfolioFilter component is defined as a functional component, which receives a prop named categories.

    Inside the component:

    We create a div with the class messonry-button text-center mb-50 to structure the filter buttons.

    There's an initial button with data-filter="*" and the class is-checked, which likely represents a button to show all items (no filtering).

    We use the .map function to iterate over the categories array (if it exists). For each category, we create a button with a data-filter attribute set to the corresponding class generated by the slugify function.

    Each button also contains a span element with the class filter-text to display the category name as filter text.

    We define PropTypes for the categories prop to specify that it should be an array.

    Finally, we export the PortfolioFilter component as the default export,
    making it available for use in other parts of the application.

*/
// Define a functional React component called PortfolioFilter
const PortfolioFilter = ({ categories }) => {
    // console.log("categories =", categories);

    return (
        <div className="messonry-button text-center mb-50">
            {/* Button to filter by "*" (e.g., show all items) */}
            <button data-filter="*" className="is-checked">
                <span className="filter-text">All</span>
            </button>
            {/* Map over 'categories' and create buttons for filtering */}
            {categories?.map((cat, idx) => (
                <button key={idx} data-filter={`.${slugify(cat)}`}>
                    {/* Display the category name as filter text */}
                    <span className="filter-text">{cat}</span>
                </button>
            ))}
        </div>
    );
};

// Define PropTypes to specify the expected shape of props
PortfolioFilter.propTypes = {
    categories: PropTypes.array, // 'categories' should be an array
};

// Export the PortfolioFilter component as the default export
export default PortfolioFilter;
