import React, { Fragment } from "react";
import PropTypes from "prop-types";

// Defining a functional component called Layout
const Layout = ({ children }) => {
    // The Layout component takes a single prop called "children"
    return <Fragment>{children}</Fragment>;
    // It renders the content passed as "children" using React Fragments
};

// Defining propTypes to specify that "children" must be a valid React node
Layout.propTypes = {
    children: PropTypes.node.isRequired,
    // PropTypes.node is a validation that ensures "children" is a valid React node
    // "isRequired" specifies that the "children" prop is required and must be provided
};

// Exporting the Layout component as the default export of this module
export default Layout;
