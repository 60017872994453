// Import useEffect hook from React
import { useEffect } from "react";

// Import withRouter from react-router-dom to access routing history
import { withRouter } from "react-router-dom";

/*
This code defines a NavScrollTop component in React that utilizes the useEffect hook to automatically scroll

the window to the top whenever the route changes.

It is wrapped with the withRouter higher-order component from react-router-dom to access the routing history.

This component is designed to be used as a wrapper around other components or routes.

When it is rendered, it automatically scrolls the window to the top using window.scrollTo(0, 0).

The withRouter higher-order component is used to ensure that

the props object passed to NavScrollTop includes the router-related properties, such as history, location, and match.
*/

// Define the NavScrollTop functional component
const NavScrollTop = (props) => {
    // useEffect hook to execute code when the component mounts or when the route changes
    useEffect(() => {
        // Scroll the window to the top
        window.scrollTo(0, 0);
    });

    // Render the children of the component (content passed to NavScrollTop)
    return props.children;
};

// Export the NavScrollTop component wrapped with withRouter
export default withRouter(NavScrollTop);
