// Importing necessary dependencies
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Functional component named PortfolioItem that takes a prop 'portfolio'
const PortfolioItem = ({ portfolio }) => {
    // Rendering the structure of a single portfolio item
    return (
        <div className="single-portfolio">
            {/* Container for the portfolio item */}
            <div className="">
                {/* Overlay for the portfolio item */}
                <div className="overlay">
                    {/* Link to the detailed portfolio page */}
                    <Link
                        to={
                            process.env.PUBLIC_URL +
                            `/portfolio/${portfolio.slug}`
                        }
                    >
                        {/* Image of the portfolio item */}
                        <img
                            src={process.env.PUBLIC_URL + portfolio.homeImage}
                            alt="portfolio"
                        />
                    </Link>
                </div>
            </div>
            {/* Content section for the portfolio item */}
            <div
                className="content"
                // Setting background color dynamically based on the portfolio data
                style={{ backgroundColor: "rgb(0, 0, 0)" }}
            >
                {/* Title of the portfolio item with a link to the detailed page */}
                <h3 className="title">
                    <Link
                        to={
                            process.env.PUBLIC_URL +
                            `/portfolio/${portfolio.slug}`
                        }
                    >
                        {portfolio.title}
                    </Link>
                </h3>
                {/* Excerpt/description of the portfolio item */}
                <p className="desc">{portfolio.excerpt}</p>
            </div>
        </div>
    );
};

// PropType validation for the 'portfolio' prop
PortfolioItem.propTypes = {
    portfolio: PropTypes.object,
};

// Exporting the PortfolioItem component as the default export
export default PortfolioItem;
