import React, { useEffect, useState } from "react";
import "../../assets/scss/style.scss";

const ProgressBar = () => {
    const [scrolled, setScrolled] = useState(0);

    const handleScroll = () => {
        const scrollPosition = window.scrollY || window.pageYOffset;
        const totalHeight =
            document.documentElement.scrollHeight - window.innerHeight;
        const scrollPercentage = (scrollPosition / totalHeight) * 100;

        const scrolled = scrollPercentage;

        setScrolled(scrolled);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []); // Empty dependency array ensures that the effect runs only once (on mount) and cleans up on unmount

    return (
        <div className="app__progress-container">
            <div
                className="app__progress-bar"
                style={{ width: `${scrolled}%` }}
            />
        </div>
    );
};

export default ProgressBar;
