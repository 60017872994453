// Import necessary modules
import PropTypes from "prop-types"; // PropTypes library for defining component prop types
import React from "react"; // React for creating components
import { Helmet } from "react-helmet"; // Helmet for managing document head metadata

// Functional component for handling SEO-related metadata
const SEO = ({ title }) => {
    return (
        <Helmet>
            {" "}
            {/* Helmet component for managing document head */}
            {/* Set character set to UTF-8 */}
            <meta charSet="utf-8" />
            {/* Set the title of the document dynamically based on the 'title' prop */}
            <title>{title}</title>
            {/* Define robots meta tag - instructs search engines not to index this page but to follow links */}
            <meta name="robots" content="noindex, follow" />
            {/* Set description meta tag for SEO */}
            <meta
                name="description"
                content="lnacarla – Photography Portfolio"
            />
            {/* Set viewport meta tag for responsive design on various devices */}
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1, shrink-to-fit=no"
            />
        </Helmet>
    );
};

// PropTypes for type-checking the 'title' prop
SEO.propTypes = {
    title: PropTypes.string,
};

// Export the SEO component
export default SEO;
