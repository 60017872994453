import AOS from "aos";
import "aos/dist/aos.css";
import "lightgallery.js/dist/css/lightgallery.css";
import { useEffect } from "react";
import "react-modal-video/scss/modal-video.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss";
import "./assets/css/animate.css";
import "./assets/css/bootstrap.min.css";
// CSS File Here
import "./assets/css/elegantIcons.css";
import "./assets/css/icofont.css";
import "./assets/scss/style.scss";
import NavScrollTop from "./components/nav-scroll-top";
import AboutPage from "./pages/about";
import Contact from "./pages/contact";
import HomePage from "./pages/index";
import Portfolio from "./pages/portfolio";
import VideoGallery from "./pages/video-gallery";
import PortfolioDetails from "./templates/portfolio-details";

/*

    + Explanation of the code:

    Import Dependencies and Styles:

    The code begins by importing various dependencies and styles required for the application, including CSS stylesheets,

    libraries like AOS for animations, and routing-related components from React Router.

    + useEffect for AOS:

    Inside the useEffect hook, the AOS library (Animate On Scroll) is initialized with specific configurations.

    It sets up animations for elements when they come into view during scrolling.

    + Router Setup:

    The application is wrapped in a Router component, which provides routing functionality to the app.
    Routing and Page Components:

    Inside the Switch component, multiple Route components are defined. Each Route corresponds to a specific URL path and renders a specific page component when the path matches.
    The process.env.PUBLIC_URL is used to handle routing when the app is deployed in subdirectories or on different domains.
    Scroll-to-Top Component:

    A NavScrollTop component is rendered at the top-level. This component likely handles the functionality to scroll to the top of the page when navigating between routes.

    + Export:

    Finally, the App component is exported as the default export, making it the entry point of the application.

*/

/*
+ what the exact attribute means ?

In the context of React Router, the exact attribute is used with the Route component

to ensure that a route is matched only when the URL exactly matches the specified path.

When you define a route without exact, React Router will match that route

if the URL partially matches the path you've specified.

This can lead to unexpected behavior if you have nested routes because multiple routes can match at the same time.

Here's an example to illustrate the difference:

- Without exact:

<Route path="/portfolio" component={Portfolio} />
<Route path="/portfolio/:id" component={PortfolioDetails} />

With this setup, if the URL is /portfolio/1, both routes will be matched because the /portfolio route partially matches the URL. 

This may not be what you want.

To make sure that only one route is matched based on the exact path, you can use the exact attribute:

- With exact:

    <Route exact path="/portfolio" component={Portfolio} />
    <Route path="/portfolio/:id" component={PortfolioDetails} />

Now, if the URL is /portfolio/1, only the second route will be matched because the /portfolio route requires an exact match for the URL to trigger it.

So, in summary, the exact attribute is used to ensure that a route is matched only when the URL path exactly matches the specified path, and not when it partially matches. It's especially useful when you have nested routes and want to avoid conflicts between them.

*/
const App = () => {
    // Initialize AOS (Animate On Scroll) library with configurations
    useEffect(() => {
        AOS.init({
            offset: 80,
            duration: 1000,
            once: true,
            easing: "ease",
        });
        AOS.refresh();
    }, []);
    return (
        <Router>
            {/* Render a component for handling scroll-to-top functionality */}
            <NavScrollTop>
                <Switch>
                    {/* Define routes for various pages */}
                    <Route
                        path={`${process.env.PUBLIC_URL + "/"}`}
                        exact
                        component={HomePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/about"}`}
                        component={AboutPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/portfolio"}`}
                        exact
                        component={Portfolio}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/video-gallery"}`}
                        component={VideoGallery}
                    />
                    {/*
                        In the code above, I've added a nested route for portfolio details under the /portfolio route. 
                        This means that when you access a URL like /portfolio/1 or /portfolio/2, 
                        it will render the PortfolioDetails component and pass the id as a route parameter. 
                        You can access this id parameter within the PortfolioDetails component using props.match.params.id. 
                    */}
                    <Route
                        path={`${process.env.PUBLIC_URL + "/portfolio/:slug"}`}
                        component={PortfolioDetails}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/contact"}`}
                        component={Contact}
                    />
                </Switch>
            </NavScrollTop>
        </Router>
    );
};

export default App;
