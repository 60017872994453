import React from "react";
import PopupVideo from "../../components/popup-video";
import PortfolioData from "../../data/portfolio.json";
import useMasonry from "../../hooks/use-masonry";

/*
    Here's a breakdown of the code:

    VideoGalleryContainer is a functional component responsible for rendering a video gallery.

    It uses the custom hook useMasonry to obtain categories for filtering the portfolio items.

    The categories variable will contain the categories extracted from the PortfolioData.

    Inside the component's return statement, it creates a grid layout for displaying portfolio items using Bootstrap classes (row-cols-*) to control the number of columns based on screen size.

    There is an intentionally empty column <div className="col"></div>. This might be used for layout purposes or to create spacing between portfolio items.

    It maps over the PortfolioData array and, for each portfolio item, renders a PopupVideo component.

    This component appears to display a video popup when clicked and receives data from the portfolio item.

    The overall purpose of this code is to create a video gallery by rendering a list of portfolio items, 

    each of which can trigger a video popup when clicked. 

    The specific behavior and appearance of the video popup are likely defined in the PopupVideo component, 

    and the useMasonry hook is used for potential filtering based on categories.
*/
const VideoGalleryContainer = () => {
    // Use the custom hook "useMasonry" to get categories for filtering
    const { categories } = useMasonry(PortfolioData, ".portfolio-list");

    return (
        <div className="portfolio-area portfolio-default-area">
            <div className="container-fluid">
                {/* Create a grid layout for portfolio items */}
                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 portfolio-list mb-n30">
                    {/* This empty column is here intentionally */}
                    <div className="col"></div>
                    {PortfolioData &&
                        PortfolioData.map((portfolio) => (
                            // Render each portfolio item with a PopupVideo component
                            <div
                                key={portfolio.id}
                                className={`col masonry-grid mb-30`}
                            >
                                {/* PopupVideo component displays a video popup */}
                                <PopupVideo data={portfolio} />
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default VideoGalleryContainer;
