// Import React, useEffect, and useState from React
import React, { useEffect, useState } from "react";

/*
    This code defines a ScrollToTop component in React, which creates a button that appears when the user scrolls down
    and allows them to scroll back to the top of the page smoothly.
    It uses the useState and useEffect hooks to manage the component's state and handle scroll events.

    The component listens for scroll events, updates the stick state based on the scroll position,
    and displays the scroll-to-top button accordingly.
    When the button is clicked, it scrolls to the top of the page smoothly.
*/

// Define the ScrollToTop functional component with 'stick' state
const ScrollToTop = (props) => {
    // State to manage whether the button should stick (be visible)
    const [stick, setStick] = useState(false);

    // Function to scroll to the top of the page smoothly
    const onClickHandler = () => {
        // window.scrollTo({ top: 0, behavior: "smooth" });
        document.querySelector("body").scrollTo(0, 0);
        // window.scrollTo(0, 0);
    };

    // useEffect hook to handle scroll events and update the 'stick' state
    useEffect(() => {
        // Variable to store the previous scroll position
        var position = window.scrollY;

        // Function to handle scroll events
        const scrollHandler = () => {
            // Get the current scroll position
            let scrollPos = window.scrollY;

            // Update 'stick' state based on scroll position
            // For Displaying/Hiding scrolltop button
            if (scrollPos < 300) {
                setStick(false);
            } else if (scrollPos < position) {
                setStick(true);
            } else {
                setStick(false);
            }

            // Update the position for the next scroll event
            position = scrollPos;
            // console.log("position =", position);
        };

        // Add event listener for scroll events
        window.addEventListener("scroll", scrollHandler);

        // Clean up by removing the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }, [stick]); // useEffect dependency on 'stick' to prevent unnecessary re-renders

    // Render the scroll-to-top button with dynamic class based on 'stick' state
    return (
        <button
            type="button"
            className={`scroll-top ${stick ? "show" : ""}`}
            onClick={onClickHandler}
            {...props}
        >
            {/* Arrow-up icons */}
            <i className="arrow-top icofont-rounded-up"></i>
            <i className="arrow-bottom icofont-rounded-up"></i>
        </button>
    );
};

// Export the ScrollToTop component as the default export
export default ScrollToTop;
