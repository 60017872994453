import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import "../../assets/scss/style.scss";
import { gsap } from "gsap";

/*
  PortfolioDetailsContainer is a functional component in React.

  It is a JavaScript function that defines the rendering logic for a specific part of the user interface.

  In this case, it renders the details of a portfolio item based on the data prop it receives.

  Functional components are a way to create reusable UI elements in React,

  and they are typically defined using ES6 arrow functions or regular JavaScript functions.


  PortfolioDetailsContainer is defined as a functional component using an arrow function:

    const PortfolioDetailsContainer = ({ data }) => {
        // Component logic and JSX rendering here...
    };

    This component takes the data prop as an argument and returns JSX elements to be rendered

    in the user interface based on the data it receives.

    It follows the functional component pattern in React, where the rendering is based on the input props and state,

    and it doesn't have its own internal state or lifecycle methods.

*/

/*

    This component, PortfolioDetailsContainer, is responsible for rendering the details of a portfolio item based on 
    
    the data prop it receives. Here's a breakdown of the component's structure and functionality:

    The component receives a data prop, which is an object containing information about the portfolio item to be displayed.

    It extracts and formats the categories of the portfolio item using the map function to create a comma-separated list.

    The component then renders various sections of portfolio details, including categories, title,

    information about the client, date, team, and services.

    It uses the dangerouslySetInnerHTML prop to render HTML content within these sections, allowing for rich text formatting.

    It also renders the main content of the portfolio item, which can include multiple HTML content sections, such as text and images.

    A link is provided to navigate to the portfolio page associated with this item.

    Additional images related to the portfolio item are displayed, including a primary image and multiple other images in different layouts.

    Social media icons for sharing the portfolio item are displayed.

    A call-to-action link is included to encourage users to start their project.

    Navigation links to previous and next portfolio items are displayed.

    PropTypes are used to specify the expected structure of the data prop.

    Overall, this component is designed to display detailed information about a portfolio item,

    including text content, images, categories, and links to navigate to related portfolio items.

*/

const PortfolioDetailsContainer = ({ data }) => {
    // Extract and format categories from the data
    const cate = data.categories.map((value, idx) => {
        return (
            <span className="d-inline" key={idx}>
                {value}
                {idx !== data.categories.length - 1 && " , "}
            </span>
        );
    });

    // Define a state variable to store the class name
    const [divClassName, setDivClassName] = useState("initial-class");
    const timeline = gsap.timeline(); // Create a GSAP timeline

    // Change the color of the body when we navigate to a new project page
    timeline.set("body", {
        backgroundColor: data.backgroundColor,
    });

    // Define a function to handle the click event
    const handleClick = () => {
        // Disable Click Event Using CSS (Avoid buggy scroll top)
        timeline.set("body", { pointerEvents: "none" });

        // Hide the scrollbar
        timeline.to("body", { overflowY: "hidden", duration: 0 });

        // Hide the content of the page at the same time
        timeline.set(".portfolio-area", { opacity: 0 });

        timeline.to(".emblem", { display: "block", opacity: 1, duration: 1 });
        // timeline.to(".portfolio-area-container", { opacity: 0, duration: 0 });

        /*
             the delay option is set to 2, which means there will be a 2-second pause between
             the first opacity animation (setting opacity to 0) and the second opacity animation (setting opacity to 1).
         */
        timeline
            .to(".portfolio-area", {
                opacity: 1,
                duration: 0.75,
                delay: 1,
            })
            /*
                The "-=1" parameter is added to the second animation to ensure that it starts 1 second before the end of the previous animation.
                This effectively makes both animations run simultaneously,
                with the second animation (scrollbar show) starting slightly earlier than the first animation's completion.
            */
            .to(
                ".emblem",
                { opacity: 0, display: "none", duration: 0.25 },
                "-=1"
            )
            .to("body", { overflowY: "auto", duration: 0.1 }, "-=1");

        // Disable Click Event Using CSS (Avoid buggy scroll top)
        timeline.set("body", { pointerEvents: "auto" });
    };

    return (
        <div
            className="portfolio-area portfolio-single"
            style={{ backgroundColor: data.backgroundColor }}
        >
            <div className="container portfolio-area-container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="inner-content">
                            <div className="content" data-aos="fade-up">
                                {/* Display categories */}
                                <p className="category">{cate}</p>
                                {/* Display portfolio title */}
                                <h3 className="title">{data.title}</h3>
                            </div>
                            {/* Display portfolio information */}
                            <div className="portfolio-info">
                                <div className="row">
                                    {/* Date information */}
                                    <div
                                        className="col-sm-6 col-md-3 col-lg-3"
                                        data-aos="fade-up"
                                        data-aos-delay="300"
                                    >
                                        <div className="info-item">
                                            <span>Date</span>
                                            {/* Render date information */}
                                            <p
                                                dangerouslySetInnerHTML={{
                                                    __html: data.date,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Display portfolio content */}
                            <div
                                className="portfolio-content"
                                data-aos="fade-up"
                            >
                                {/* Render portfolio content */}
                                {/* {data.body.map((value, i) => {
                                    return (
                                        <div
                                            key={i}
                                            className="content-wrap"
                                            dangerouslySetInnerHTML={{
                                                __html: value,
                                            }}
                                        />
                                    );
                                })} */}
                                {/* Link to the portfolio page */}
                                {/* <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        data.pageUrl.link
                                    }
                                >
                                    {data.pageUrl.text}
                                </Link> */}
                            </div>
                            {/* Display portfolio images */}
                            <div
                                className="thumb section-padding-bottom"
                                data-aos="fade-up"
                                data-aos-delay="300"
                            >
                                <img
                                    className="w-100"
                                    src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`}
                                    alt="Alexis"
                                />
                            </div>
                            <div className="row">
                                <div
                                    className="col-lg-8 m-auto"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                >
                                    <div className="thumb section-padding-bottom">
                                        <img
                                            className="w-100"
                                            src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`}
                                            alt="Alexis"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Display additional portfolio images */}
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <div className="thumb section-padding-bottom">
                            <img
                                className="w-100"
                                src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`}
                                alt="lnacarla"
                            />
                        </div>
                    </div>
                </div>
                <div className="row thumb style-two">
                    <div
                        className="col-md-6 col-lg-5 pl-sm-15 pl-0"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <img
                            className="mb-xs-30 mb-sm-60"
                            src={`${process.env.PUBLIC_URL}/${data.gallery.imageFour}`}
                            alt="lnacarla"
                        />
                    </div>
                    <div
                        className="col-md-6 col-lg-7 text-end pr-sm-15 pr-0"
                        data-aos="fade-up"
                        data-aos-delay="600"
                    >
                        <img
                            className="sm-pl-0 pl-md-50"
                            src={`${process.env.PUBLIC_URL}/${data.gallery.imageFive}`}
                            alt="lnacarla"
                        />
                    </div>
                </div>
            </div>
            {/* Display social icons */}
            <div className="container">
                <div className="row nav-container">
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-delay="600"
                    >
                        {/* Display navigation to previous and next projects */}
                        <div className="portfolio-navigation">
                            <div className="prev">
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        `/portfolio/${data.previousProjectSlug}`
                                    }
                                    onClick={handleClick}
                                >
                                    <i className="arrow_carrot-left"></i>{" "}
                                    Previous
                                </Link>
                                {data.previousProjectTitle}
                            </div>
                            <div className="next">
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        `/portfolio/${data.nextProjectSlug}`
                                    }
                                    onClick={handleClick}
                                >
                                    Next <i className="arrow_carrot-right"></i>
                                </Link>
                                {data.nextProjectTitle}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

PortfolioDetailsContainer.propTypes = {
    data: PropTypes.object,
};

export default PortfolioDetailsContainer;
