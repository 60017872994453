// Import the ContactInfo component and the contact data from a JSON file
import ContactInfo from "../../components/contact-info";
import ContactData from "../../data/global/contact.json";

// Functional component for displaying the contact information and form
const ContactContainer = () => {
    return (
        // eslint-disable-next-line prettier/prettier
        <div className="contact-area"> {/* Container for the entire contact area */}
            <div className="container">
                <div className="row">
                    <div
                        className="col-md-10 col-lg-8 m-auto text-center"
                        data-aos="fade-up"
                    >
                        {/* Title for the contact section */}
                        <h2 className="title">
                            Contact me for any further questions
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-xl-10 m-auto">
                        <div className="contact-info-content">
                            {ContactData &&
                                ContactData.map((single, key) => {
                                    return (
                                        // Render the ContactInfo component for each set of contact data
                                        <ContactInfo data={single} key={key} />
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-xl-10 m-auto">
                        <div className="contact-form">
                            {/*
                                The following lines are commented out, presumably because 
                                the ContactForm component is not imported or used.
                            */}
                            {/* <div className="text-center" data-aos="fade-up">
                                <h2 className="title">Get In Touch</h2>
                            </div> */}
                            {/* <ContactForm /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactContainer; // Export the ContactContainer component
