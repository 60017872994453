// Import necessary modules
import AboutData from "../../../data/global/about.json"; // Import data related to the about section from a JSON file

// Functional component for displaying a gallery in the about section
const AboutGallery = () => {
    return (
        <div className="gallery-area">
            {" "}
            {/* Container for the gallery area in the about section */}
            <div className="container-fluid pl-0 pr-0">
                {" "}
                {/* Full-width container with no padding on the left and right */}
                <div className="row">
                    {" "}
                    {/* Row for gallery items */}
                    {/* First gallery item */}
                    <div
                        className="col-sm-6 col-md-6 col-lg-6"
                        data-aos="fade-up" // Apply fade-up animation
                    >
                        <div className="thumb mb-30">
                            {" "}
                            {/* Container for the image with margin-bottom */}
                            <img
                                className="w-100"
                                src={`${process.env.PUBLIC_URL}/${AboutData[4].gallery.imageOne}`}
                                alt="Alexis" // Alt text for accessibility
                            />
                        </div>
                    </div>
                    {/* Second gallery item */}
                    <div
                        className="col-sm-6 col-md-6 col-lg-6"
                        data-aos="fade-up"
                        data-aos-delay="300" // Add delay to fade-up animation
                    >
                        <div className="thumb mb-30">
                            <img
                                className="w-100"
                                src={`${process.env.PUBLIC_URL}/${AboutData[4].gallery.imageTwo}`}
                                alt="Alexis"
                            />
                        </div>
                    </div>
                    {/* Third gallery item */}
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-delay="600" // Add delay to fade-up animation
                    >
                        <div className="thumb">
                            <img
                                className="w-100"
                                src={`${process.env.PUBLIC_URL}/${AboutData[4].gallery.imageThree}`}
                                alt="Alexis"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutGallery;
