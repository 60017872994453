// Importing necessary components and libraries
import React from "react";
import SwiperCore, { Pagination } from "swiper";
import "swiper/components/navigation/navigation.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import Intro from "../../../components/intro/index";
import HomeData from "../../../data/home.json";

/*
    This component represents an introductory slider using Swiper, displaying slides with fade effect and pagination.
    Each slide is rendered using the Intro component,
    and the configuration options for Swiper are defined in the swiperOption object.
*/
// Initializing Swiper with Pagination
SwiperCore.use([Pagination]);

// Functional component for the IntroContainer
const IntroContainer = () => {
    // Swiper options for configuring the slider
    const swiperOption = {
        loop: true,
        speed: 750,
        spaceBetween: 0,
        slidesPerView: 1,
        pagination: { clickable: true },
        autoplay: {
            delay: 5500,
            disableOnInteraction: false,
        },
    };

    return (
        // Container div for the intro slider
        <div className="intro-slider-wrap">
            {/* Swiper component representing the intro slider */}
            <Swiper effect="fade" className="intro-slider" {...swiperOption}>
                {/* Mapping through HomeData to render Intro components */}
                {HomeData[0].slider &&
                    HomeData[0].slider.map((single, key) => {
                        return (
                            <SwiperSlide key={key}>
                                {/* Intro component to display individual intro items */}
                                <Intro data={single} key={key} />
                            </SwiperSlide>
                        );
                    })}
            </Swiper>
        </div>
    );
};

export default IntroContainer; // Exporting the IntroContainer component
