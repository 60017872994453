import { Link } from "react-router-dom";
import FooterLogo from "../../components/footer-logo";

const Footer = () => {
    return (
        // Footer component
        <footer className="footer-area reveal-footer border-top-style">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="footer-content">
                            {/* Widget for footer logo */}
                            <div className="widget-item text-center"></div>
                            {/* Widget for social media links */}
                            <div className="widget-item">
                                <ul className="widget-social">
                                    <li>
                                        {/* Facebook link */}
                                        <a
                                            href="https://www.facebook.com/LNACARLA"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="social_facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        {/* Instagram link */}
                                        <a
                                            href="https://www.instagram.com/lnacarla/"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <i className="social_instagram"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
