// Importing the PageTitle component
import PageTitle from "../../../components/page-title";

/*
    This component defines a page title area with a container, row, and column structure using Bootstrap.
    It includes a PageTitle component for displaying the main title and subtitle with additional styling options.
    The component also contains a thumbnail image with fade-up animation effects.
    If you have any specific questions or need further explanations, feel free to ask!
*/

// Functional component for the PageTitleContainer
const PageTitleContainer = () => {
    return (
        <div className="page-title-area">
            {" "}
            {/* Container for the page title area */}
            <div className="container">
                {" "}
                {/* Bootstrap container */}
                <div className="row">
                    {" "}
                    {/* Bootstrap row */}
                    <div className="col-lg-12" data-aos="fade-up">
                        {" "}
                        {/* Bootstrap column for the page title */}
                        <PageTitle
                            classOption="page-title-content content-style2 text-center" // Additional class options for styling
                            subTitle="" // Subtitle, if any
                            title="OUVREZ LES YEUX" // Main title
                        />
                    </div>
                </div>
            </div>
            <div className="thumb" data-aos="fade-up" data-aos-delay="300">
                {" "}
                {/* Thumbnail image */}
                <img
                    className="w-100"
                    src={
                        process.env.PUBLIC_URL +
                        "./img/about/profile-lnacarla-red.jpg" // Image source
                    }
                    alt="Alexis" // Alt text for the image
                />
            </div>
        </div>
    );
};

export default PageTitleContainer; // Export the PageTitleContainer component
