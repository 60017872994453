/* eslint-disable prettier/prettier */

// Import React and useEffect hook from React
import React, { useEffect } from "react";

// Import styles from the SCSS file
import "../../assets/scss/style.scss";

/*
This component uses the useEffect hook to initialize the emblem animation when the component mounts.

The initEmblem function rotates and translates each letter in the text to create a visually appealing emblem effect.

The cleanup function inside useEffect is currently empty, but you can add cleanup code if needed when the component unmounts.

The rendered JSX displays the loader with the text "Ouvrezlesyeux*". The SCSS styles are imported to apply any styling needed for the loader.

*/
// Define the LoaderComponent functional component
const LoaderComponent = () => {
    // useEffect hook to run initialization code when the component mounts
    useEffect(() => {
        // Function to initialize the emblem animation
        const initEmblem = (el, str) => {
            const element = document.querySelector(el);
            const text = str ? str : element.innerHTML;
            element.innerHTML = "";

            // Loop through each letter in the text
            for (let i = 0; i < text.length; i++) {
                const letter = text[i];
                const span = document.createElement("span");
                const node = document.createTextNode(letter);
                const r = (360 / text.length) * i;
                const x = (Math.PI / text.length).toFixed(0) * i;
                const y = (Math.PI / text.length).toFixed(0) * i;

                // Apply rotation and translation to each span element
                span.appendChild(node);
                span.style.webkitTransform = `rotateZ(${r}deg) translate3d(${x}px,${y}px,0)`;
                span.style.transform = `rotateZ(${r}deg) translate3d(${x}px,${y}px,0)`;
                element.appendChild(span);
            }
        };

        // Call the initEmblem function with the selector for the emblem
        initEmblem(".emblem");

        // Cleanup function (if needed) when the component unmounts
        return () => {
            // Cleanup code can be added here
        };
    }, []); // Empty dependency array to ensure useEffect runs only once

    // JSX rendering the loader with the emblem text
    return <div className="emblem">Ouvrez*les*yeux*</div>;
};

// Export the LoaderComponent as the default export
export default LoaderComponent;
