// Importing necessary components and data
import { Swiper, SwiperSlide } from "swiper/react";
import Brand from "../../../components/brand";
import AboutData from "../../../data/global/about.json";

// Functional component for displaying a brand logo slider
const BrandContainer = () => {
    return (
        <div className="brand-logo-area">
            {" "}
            {/* Container for the brand logo area */}
            <div className="container">
                <div className="row">
                    <div
                        className="col-lg-12 text-center pt-4"
                        data-aos="fade-up"
                    >
                        {/* Title and excerpt for the brand section */}
                        <h2 className="title">{AboutData[6].title}</h2>
                        <p>{AboutData[6].excerpt}</p>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-lg-12"
                        data-aos="fade-up"
                        data-aos-delay="300"
                    >
                        <div className="swiper-container brand-logo-slider-container">
                            {/* Swiper component for the brand logo slider */}
                            {/* WARNING slidesPerView is very important */}
                            <Swiper
                                className="brand-logo-slider"
                                spaceBetween={30}
                                slidesPerView={3}
                                loop
                                breakpoints={{
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 110,
                                    },
                                    576: {
                                        slidesPerView: 3,
                                        spaceBetween: 60,
                                    },
                                    320: {
                                        slidesPerView: 2,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {/* Mapping through brand data to create individual slides if AboutData[6].brand exists or not null*/}
                                {AboutData[6].brand &&
                                    AboutData[6].brand.map((single, key) => {
                                        return (
                                            <SwiperSlide key={key}>
                                                {/* Brand component for each slide */}
                                                {/* The router link is inside the Brand Component */}
                                                <Brand
                                                    data={single}
                                                    key={key}
                                                />
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandContainer; // Export the BrandContainer component
