// Import PropTypes library for defining the prop types of the component
import PropTypes from "prop-types";

/*

    This code defines a Qute component in React, which seems to be a component for displaying a quote in a slider or content area.

    It utilizes the dangerouslySetInnerHTML attribute to render HTML content inside the <h2> element.

    This component takes a prop named data, which is expected to be an object containing a property named qute.

    The dangerouslySetInnerHTML attribute is used to render HTML content inside the <h2> element.

    The data.qute is assumed to contain HTML-formatted content.

    The data-aos="fade-up" attribute is used for a fade-up animation,

    suggesting that this component might be part of a slider or content area with animated transitions.

*/
// Define the Qute functional component with 'data' prop
const Qute = ({ data }) => {
    return (
        // Container div for the slider content area with a fade-up animation
        <div className="slider-content-area" data-aos="fade-up">
            {/* Render HTML content inside an <h2> element */}
            <h2 dangerouslySetInnerHTML={{ __html: data.qute }}></h2>
        </div>
    );
};

// Define prop types for the Qute component
Qute.propTypes = {
    data: PropTypes.object, // Expects a prop 'data' of type object
};

// Export the Qute component as the default export
export default Qute;
