import React from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import AboutAward from "../containers/about/about-award";
import AboutService from "../containers/about/about-service/index.jsx";
import Blockquote from "../containers/about/blockquote";
import AboutGallery from "../containers/about/gallery";
import TeamContainer from "../containers/about/team";
import BrandContainer from "../containers/global/brand";
import PageTitleContainer from "../containers/global/page-title";
import Footer from "../layouts/footer";
import Header from "../layouts/header/index";
import Layout from "../layouts/index";
import ContactContainer from "../containers/contact";
import { gsap } from "gsap";

const AboutPage = () => {
    // Change the color of the body when we navigate to a home page
    const timeline = gsap.timeline(); // Create a GSAP timeline
    timeline.set("body", {
        backgroundColor: "#F5F5F5",
    });

    return (
        <React.Fragment>
            {/* Wrapping the content in a Layout component */}
            <Layout>
                {/* Setting SEO metadata */}
                <SEO title="lnacarla – About" />
                <div className="wrapper about-page-wrapper">
                    {/* Including a Header component with a specific class option */}
                    <Header classOption="hb-border" />
                    <div className="main-content">
                        {/* Including a PageTitleContainer component */}
                        <PageTitleContainer />
                        {/* Including an AboutService component */}
                        {/* <AboutService /> */}
                        {/* Including a TeamContainer component */}
                        {/* <TeamContainer /> */}
                        {/* Including a Blockquote component */}
                        <Blockquote />
                        {/* Including an AboutGallery component */}
                        <AboutGallery />
                        {/* Including an AboutAward component */}
                        {/* <AboutAward /> */}
                        {/* Including a BrandContainer component */}
                        <BrandContainer />
                        {/* Including a ContactContainer component */}
                        <ContactContainer />
                    </div>
                    {/* Including a Footer component */}
                    <Footer />
                    {/* Including a ScrollToTop component */}
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default AboutPage;
