// Import PropTypes library for defining the prop types of the component
import PropTypes from "prop-types";

/*
 the component takes a prop named data, which is expected to be an object.
 The data object likely contains information like the delay for animation and the actual contact information content.
*/
// Define the ContactInfo functional component with data as a prop
const ContactInfo = ({ data }) => {
    return (
        // Container div with styling and animation attributes
        <div
            className="info-item info-item2"
            data-aos="fade-up"
            data-aos-delay={data.delay}
        >
            {/* Commented out section for an info title and icon */}
            {/* <div className="info-title">
                <h4>{data.title}</h4>
                <div className="icon">
                    <i className={data.icon}></i>
                </div>
            </div> */}

            {/* Display contact information content with HTML formatting */}
            <div
                className="info-content"
                dangerouslySetInnerHTML={{ __html: data.info }}
            />
        </div>
    );
};

// Define prop types for the ContactInfo component
ContactInfo.propTypes = {
    data: PropTypes.object, // Expects a prop 'data' of type object
};

// Export the ContactInfo component as the default export
export default ContactInfo;
