// Import PropTypes library for defining the prop types of the component
import PropTypes from "prop-types";

/*
    This code defines a PageTitle component in React that represents a title section for a page.
    It allows customization of the subtitle, title, and an optional class for styling.
    Additionally, it provides default values for the optional classOption prop.
    This component allows you to pass in subTitle, title, and an optional classOption for styling.
    If classOption is not provided, it defaults to "content-style2 text-center".
    The component is flexible and can be used to display a title section with customizable content and styling.
*/
// Define the PageTitle functional component with 'subTitle', 'title', and 'classOption' props
const PageTitle = ({ subTitle, title, classOption }) => {
    return (
        // Container div with classes for styling
        <div className={`page-title-content ${classOption}`}>
            {/* Subtitle element */}
            <h4 className="page-title">{subTitle}</h4>

            {/* Title element */}
            <h2 className="title">{title}</h2>
        </div>
    );
};

// Define prop types for the PageTitle component
PageTitle.propTypes = {
    subTitle: PropTypes.string, // Expects a prop 'subTitle' of type string
    title: PropTypes.string, // Expects a prop 'title' of type string
    classOption: PropTypes.string, // Expects a prop 'classOption' of type string
};

// Set default values for optional props
PageTitle.defaultProps = {
    classOption: "content-style2 text-center", // Default value for 'classOption' if not provided
};

// Export the PageTitle component as the default export
export default PageTitle;
