// Import PropTypes library for defining the prop types of the component
import PropTypes from "prop-types";

// Import NavLink from react-router-dom and utility functions from "../../utils"
import { NavLink } from "react-router-dom";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../utils";

/*
    This component creates a mobile-friendly off-canvas menu with navigation links.
    The onClickHandler function is responsible for handling the click events on menu items,
    toggling the visibility of their child UL elements. It uses utility functions from ../../utils for DOM manipulation.
    The NavLink components are used for navigation within the application.
    The overall structure of the off-canvas menu includes a header, menu items, and a footer.
*/

// Define the HamburgerMenu functional component with 'show' and 'onClose' props
const HamburgerMenu = ({ show, onClose }) => {
    // Click event handler for the menu items
    const onClickHandler = (e) => {
        // Extract relevant elements and classes from the event target
        const target = e.currentTarget;
        const parentEl = target.parentElement;

        // Check if the clicked element or its parent has the "menu-toggle" class
        if (
            parentEl?.classList.contains("menu-toggle") ||
            target.classList.contains("menu-toggle")
        ) {
            // Determine the target element based on the presence of the "icon" class
            const element = target.classList.contains("icon")
                ? parentEl
                : target;

            // Find the closest parent 'li' element
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;

            // Get siblings of the parent element
            const parentSiblings = getSiblings(parent);

            // Collapse other menu items
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });

            // Toggle the visibility of the clicked menu item's child 'UL'
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };

    // Render the off-canvas menu
    return (
        <aside className={`off-canvas-wrapper ${show ? "active" : ""}`}>
            <div className="off-canvas-inner">
                <div className="off-canvas-overlay"></div>
                <div className="off-canvas-content">
                    <div className="off-canvas-header">
                        <div className="close-action">
                            {/* Close button with an onClick handler */}
                            <button className="btn-close" onClick={onClose}>
                                <i className="icon_close"></i>
                            </button>
                        </div>
                    </div>

                    <div className="off-canvas-item">
                        <div className="asside-navigation-area">
                            {/* Menu items with NavLink for navigation */}
                            <ul className="asside-menu">
                                <li className="item">
                                    <NavLink
                                        exact
                                        to={process.env.PUBLIC_URL + "/"}
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={
                                            process.env.PUBLIC_URL +
                                            "/portfolio"
                                        }
                                    >
                                        <span>Portfolio</span>
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        to={process.env.PUBLIC_URL + "/about"}
                                    >
                                        About
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="off-canvas-footer"></div>
                </div>
            </div>
        </aside>
    );
};

// Define prop types for the HamburgerMenu component
HamburgerMenu.propTypes = {
    show: PropTypes.bool, // Expects a prop 'show' of type boolean
    onClose: PropTypes.func, // Expects a prop 'onClose' of type function
};

// Export the HamburgerMenu component as the default export
export default HamburgerMenu;
