// Import necessary modules
import AboutData from "../../../data/global/about.json"; // Import data related to the about section from a JSON file

// Functional component for displaying a blockquote in the about section
const Blockquote = () => {
    return (
        <div className="blockquote-area">
            {" "}
            {/* Container for the blockquote area in the about section */}
            <div className="container">
                <div className="row">
                    <div className="col-lg-12" data-aos="fade-up">
                        <blockquote className="blockquote-style">
                            {" "}
                            {/* Blockquote with a specific style */}
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: AboutData[3].excerpt,
                                }}
                            />{" "}
                            {/* Display the HTML-formatted excerpt in the blockquote */}
                            <div className="author-info">
                                {" "}
                                {/* Author information section */}
                                <span className="name">
                                    {AboutData[3].name}{" "}
                                    {/* Display the name of the author */}
                                </span>
                                <span className="job">
                                    {AboutData[3].designation}{" "}
                                    {/* Display the designation/job of the author */}
                                </span>
                            </div>
                        </blockquote>
                    </div>
                </div>
            </div>
        </div>
    );
};

// Export the Blockquote component
export default Blockquote;
