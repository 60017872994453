// Importing React and the Qute component along with home data
import React from "react";
import Qute from "../../../components/qute/index";
import HomeData from "../../../data/home.json";

// Functional component for the Qute container
const QuteContainer = () => {
    return (
        <div className="home-slider-area slider-photographer">
            {" "}
            {/* Container for the Qute section */}
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-11 col-xl-10 m-auto">
                        {/* Rendering the Qute component with data from HomeData */}
                        <Qute data={HomeData[1]} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuteContainer; // Export the QuteContainer component
