// Import PropTypes library for defining the prop types of the component
import PropTypes from "prop-types";

// Import React and useState hook from React
import React, { useState } from "react";

// Import ModalVideo component from "react-modal-video" library
import ModalVideo from "react-modal-video";
/*
    This code defines a PopupVideo component in React, which appears to be a component

    for displaying either an image or a YouTube video in a popup/modal.

    It utilizes the react-modal-video library for handling the modal functionality.

    This component checks the type property of the data prop and renders either an image or a video (YouTube)
    based on the value of type.
    The modal is used to display the video when the play button is clicked.
*/

// Define the PopupVideo functional component with 'data' prop
const PopupVideo = ({ data }) => {
    // State to manage the modal open/close status
    const [isOpen, setOpen] = useState(false);

    return (
        // Container div for the single portfolio item
        <div className="single-portfolio">
            {/* Conditionally render content based on the 'type' property of 'data' */}
            {data?.type === "image" && (
                // If 'type' is 'image', render an image with a play button
                <div className="thumbnail">
                    <div className="overlay">
                        {/* Image */}
                        <img
                            src={process.env.PUBLIC_URL + data.homeImage}
                            alt="portfolio"
                        />
                    </div>
                    {/* Play button that opens the modal when clicked */}
                    <div className="play-btn">
                        <button onClick={() => setOpen(true)}>
                            {/* Play icon */}
                            <i className="icofont-play-alt-2"></i>
                        </button>
                    </div>
                </div>
            )}

            {data?.type === "video" && (
                // If 'type' is 'video', render an iframe with the video URL
                <iframe
                    className="iframe-video"
                    src={data.videoUrl}
                    title="YouTube video player"
                ></iframe>
            )}

            {/* ModalVideo component for handling video modals */}
            <ModalVideo
                channel="youtube" // Specifies the video channel (YouTube in this case)
                autoplay // Autoplay the video when the modal opens
                isOpen={isOpen} // Controls whether the modal is open or closed
                videoId="KzhSW1Ztq1w" // Default video ID (can be changed)
                onClose={() => setOpen(false)} // Function to be executed when the modal is closed
            />
        </div>
    );
};

// Define prop types for the PopupVideo component
PopupVideo.propTypes = {
    data: PropTypes.object, // Expects a prop 'data' of type object
};

// Export the PopupVideo component as the default export
export default PopupVideo;
